import * as React from 'react';
import classNames from 'classnames';
import { IResponsiveBoxProps } from '../../../Container.types';
import ResponsiveContainer from '../../../../../thunderbolt-core-components/ResponsiveContainer/viewer/ResponsiveContainer';
import commonStyles from '../../shared/common.scss';
import styles from './ResponsiveBox.scss';

// This container is used in responsive site
const ResponsiveBox: React.FC<IResponsiveBoxProps> = ({
  id,
  containerProps,
  containerRootClassName = '',
  children,
  role,
  onClick,
  onDblClick,
  onMouseEnter,
  onMouseLeave,
  hasPlatformClickHandler,
  supportPinnedToContainer,
}) => {
  return (
    <div
      id={id}
      {...(role ? { role } : {})}
      className={classNames(styles.root, containerRootClassName, {
        [commonStyles.clickable]: hasPlatformClickHandler,
      })}
      onDoubleClick={onDblClick}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={styles.bg} />
      {supportPinnedToContainer ? (
        children() // contains: (1) ResponsiveContainer with relative children, (2) DynamicStructureContainer with pinned children
      ) : (
        <ResponsiveContainer {...containerProps}>
          {children}
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default ResponsiveBox;
